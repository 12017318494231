<template>
  <div class="share-box">
    <van-nav-bar
      class="nav-bar"
      title="Xác minh đại lý"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i
        slot="left"
        class="iconfont icon-jiantou1"
        @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"
      ></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'"
          >Xác minh đại lý</span
        >
      </template>
      <template #right>
        <p
          class="nav-bar-server-text"
          @click="handleLinkService"
          :class="{ 'yellow-color': !$isLinkGame, 'black-bc': !$isLinkGame }"
        ></p>
      </template>
    </van-nav-bar>
    <div class="form-box">
      <div class="form-item" v-for="(item, index) in formList" :key="index">
        <span>{{ item.label }}</span>
        <van-switch
          v-if="item.type === 'switch'"
          v-model="form[item.key]"
          @change="handleSwitch($event, item.key)"
          size="23px"
        />
        <input v-else type="text" v-model="form[item.key]" />
      </div>
      <button @click="handleCommit()">Gửi Thông Tin</button>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
import { Dialog } from 'vant'

export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      form: {
        shenbianggc: false,
        shenbian: false,
        shenbian6hc: false,
        shenbiantc: false,
        shenbianpl: '',
        tuiguang: '',
        jianyi: '',
      },
      serviceURL: '',
      formList: [
        {
          key: 'shenbianggc',
          label: 'Ai xung quanh bạn mua vết xước?',
          isSwitch: false,
          type: 'switch',
        },
        {
          key: 'shenbian',
          label: 'Có ai xung quanh bạn đã mua vé số không',
          isSwitch: false,
          type: 'switch',
        },
        {
          key: 'shenbian6hc',
          label: 'Xung quanh bạn có ai mua xổ số Mark Six ở Hồng Kông',
          isSwitch: false,
          type: 'switch',
        },
        {
          key: 'shenbiantc',
          label: 'Có ai xung quanh bạn đã mua một câu đố thể thao không',
          isSwitch: false,
          type: 'switch',
        },

        {
          key: 'shenbianpl',
          label: 'Bạn bè của bạn có thể mua bao nhiêu vé số',
          type: 'input',
        },
        {
          key: 'tuiguang',
          label: 'làm thế nào để thúc đẩy',
          type: 'input',
        },
        {
          key: 'jianyi',
          label: 'Bạn có lời khuyên nào tốt Chi tiết cho nền tảng này',
          type: 'input',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getService()
  },
  mounted() {},
  methods: {
    /* handle */
    handleLinkService() {
      location.href = this.serviceURL
    },
    handleSwitch(val, key) {
      this.form[key] = val
    },
    handleCommit() {
      for (const key in this.form) {
        if (this.form[key] === '') {
          this.$toast('Vui lòng điền các thông tin liên quan theo yêu cầu.')
          return false
        }
      }
      this.commitEvent()
    },
    /* API */
    async getService() {
      const {
        data: { data, ret },
      } = await request({
        method: 'get',
        url: 'other/config',
        params: {
          act: 'system',
          refresh: this.isLoading === true ? 1 : 0,
        },
      })
      if (ret === 1) {
        this.serviceURL = data.service_url
      }
    },
    async commitEvent() {
      const { data } = await request({
        method: 'post',
        url: 'agent/handle/apply',
        params: {
          content: { ...this.form },
        },
      })
      if (data.ret === 1) {
        Dialog.alert({
          message: 'Ứng dụng đại lý thành công',
        }).then(() => {
          this.$router.back()
        })
      } else {
        Dialog.alert({
          message: data.msg,
        }).then(() => {
          this.$router.back()
        })
      }
    },
    /* 其他 */
  },
}
</script>

<style lang="less" scoped>
.share-box {
  height: 100vh;
  font-family: 'PingFang-Regular';
  position: relative;
  box-sizing: border-box;
  background-color: #fff;

  .nav-bar {
    width: 100%;
    // z-index: 9999;
  }
  .nav-bar-server-text {
    color: #fff;
  }
  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      // background-color: #ff0000;
    }
    /deep/ .van-nav-bar__title {
      // color: #fff;
    }
    button {
      background-color: #eeeeee;
      border: none;
      border-radius: 5px;
      color: rgb(51, 20, 20);
      font-size: 34px;
    }
    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
    /deep/ .van-popover {
      .van-popover__content {
        width: 405px;
      }
      /deep/ .van-popover__action {
        /deep/ .van-popover__action-text {
          width: 100px;
        }
      }
    }
  }
  > .form-box {
    > .form-item {
      border-bottom: 1px solid #eeeeee;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 28px;
      padding: 15px 20px;
      > input {
        width: 250px;
        border: 0;
        border-bottom: 1px solid #d7d7d7;
        text-align: right;
      }
    }
    > button {
      width: 90%;
      height: 100px;
      margin: 50px auto 0;
      display: block;
      background-color: #dc1323;
      color: #fff;
      font-size: 30px;
      border-radius: 5px;
      border: 0;
    }
  }
}
</style>
